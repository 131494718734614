<template>
  <v-container>
    <h2>{{$t('message.profile-page-title')}}</h2>
    <v-card class="mt-7 mb-7" flat>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col class="ms-5" md="5" >
                <span class="label-title">{{$t('message.company-name')}}</span>
              </v-col>
              <v-col v-if="!profiles.company_name">
                <span>-</span>
              </v-col>
              <v-col>
                <span class="label">{{ profiles.company_name }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row >
          <v-col cols="12">
            <v-row>
              <v-col class="ms-5" md="5" >
                <span class="label-title">{{$t('message.display-name')}}</span>
              </v-col>
              <v-col>
                <span class="label">{{ profiles.merchant_name }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row >
          <v-col cols="12">
            <v-row>
              <v-col class="ms-5" md="5" >
                <span class="label-title">{{$t('message.pic-name')}}</span>
              </v-col>
              <v-col>
                <span class="label">{{ profiles.pic_name }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row >
          <v-col cols="12">
            <v-row>
              <v-col class="ms-5" md="5" >
                <span class="label-title">{{$t('message.phone-number')}}</span>
              </v-col>
              <v-col>
                <span class="label">{{ profiles.phone }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row >
          <v-col cols="12">
            <v-row>
              <v-col class="ms-5" md="5" >
                <v-row>
                  <v-col cols="4" >
                    <span class="label-title">{{$t('message.bank-account')}}</span>
                  </v-col>
                  <v-col cols="6">
                    <v-row>
                      <span class="label-title">{{$t('message.bank-name')}}</span>
                    </v-row>
                    <v-row>
                      <span class="label-title">{{$t('message.branch')}}</span>
                    </v-row>
                    <v-row>
                      <span class="label-title">{{$t('message.account-number')}}</span>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col cols="12" md="12" xs="12" class="nested-label-col-12" >
                    <v-row>
                      <span class="label">-</span>
                    </v-row>
                    <v-row>
                      <span class="label">-</span>
                    </v-row>
                    <v-row v-if="!profiles.bank_account">
                      <span class="label">-</span>
                    </v-row>
                    <v-row v-else>
                      <span class="label">{{ profiles.bank_account }}</span>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row >
          <v-col cols="12">
            <v-row>
              <v-col class="ms-5" md="5" >
                <span class="label-title">{{$t('message.fee')}}</span>
              </v-col>
              <v-col v-if="!profiles.fee">
                <span class="label">-</span>
              </v-col>
              <v-col v-else>
                <span class="label">{{ profiles.fee }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row >
          <v-col cols="12">
            <v-row>
              <v-col class="ms-5" md="5" >
                <span class="label-title">{{$t('message.postal-code')}}</span>
              </v-col>
              <v-col>
                <span class="label">〒{{ formatPostalcode(profiles.postalcode) }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row >
          <v-col cols="12">
            <v-row>
              <v-col class="ms-5" md="5" >
                <span class="label-title">{{$t('message.prefecture')}}</span>
              </v-col>
              <v-col>
                <span class="label">{{ profiles.prefecture }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row >
          <v-col cols="12">
            <v-row>
              <v-col class="ms-5" md="5" >
                <span class="label-title">{{$t('message.address')}}</span>
              </v-col>
              <v-col>
                <span class="label">{{ profiles.address }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      <!-- </div> -->
    </v-card>
    <div class="d-flex flex-row-reverse mt-3 me-0" tile>
      <div class="m-2">
        <v-btn color="primary" type="button" @click.prevent="$router.push('/profile/update')">
          {{ $t("message.label-update") }}
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Profile",
  data() {
    return {
    }
  },
  methods: {
    formatPostalcode(postalcode) {
      if(postalcode != null && postalcode != undefined) {
        let convertStr = postalcode.toString()
        let newPostcode = convertStr.slice(0, 3)+'-'+convertStr.slice(3)
        return newPostcode
      }
    },
    async fetchProfile(){
      await this.$store.dispatch('profile_module/fetchProfile')
    }
  },
  computed: {
    profiles() {
      return this.$store.state.profile_module.profileResult
    }
  },
  created(){
    this.fetchProfile()
  }
};
</script>

<style scoped>
@media only screen and (max-width: 640px) {
  .nested-label-col-2 {
    padding-top: 0;
    padding-bottom: 0;
    width: 200vw;
    margin-left: 17px;
  }
  .nested-label-col-3 {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.label {
  text-transform: capitalize;
}
.label-title {
  text-transform: capitalize;
  color: #3f51b5;
  font-weight: bold;
}
</style>
